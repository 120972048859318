@import '../../styles/_colors.scss';

.psf-navbar {
  // background-color: $psf-yellow;
  // padding-top: 0 !important;
  // padding-bottom: 0 !important;
  color: $psf-green;
  z-index: 1000;
}

.psf-navbar img {
  border: 1px solid $psf-green;
  border-radius: 20px;
  padding: 3px;
  margin-right: 15px;
  background-color: #fff;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 768px) {
  /*.psf-navbar img {
    height: 30px;
    width: 30px;
  }*/

  .psf-navbar .active {
    border-left: 3px solid $psf-green;
    padding-left: 15px !important;
    border-bottom: 0px !important;
    font-weight: 700;
  }
}

.psf-navbar .active {
  border-bottom: 3px solid $psf-green;
}

#userButton img {
  border: $psf_green_light 2px solid;
  background: transparent;
  margin: 0 10px 0 0;
  padding: 0;
  height: 25px;
  width: 25px;
}

#loginButton img {
  border: $psf_green_light 2px solid;
  background: transparent;
  margin: 0 0 0 10px;
  padding: 0;
  height: 25px;
  width: 25px;
}
