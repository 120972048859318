@import "./colors.scss";



.text-trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: 800;
}

.text-error {
  color: $psf-text-error;
}

.text-warn {
  color: $psf-text-warn;
}

.text-mute {
  color: $psf-text-green-light;
}

.text-yellow {
  color: $psf-yellow;
}

.text-yellow-light {
  color: $psf-text-yellow-light;
}

.text-green {
  color: $psf-green;
}

.text-green-alt {
  color: $psf-green-alt;
}

.text-green-light {
  color: $psf-green-light;
}

.text-green-light-alt {
  color: $psf-text-green-light;
}

.text-gray {
  color: $psf-text-gray;
}

.text-legends-silver {
  color: $psf-legends-silver;
}

.text-shadow-light {
  text-shadow: 0 0 10px $psf-text-green-light;
}

.text-shadow-yellow-light {
  text-shadow: 0 0 10px $psf-text-yellow-light;
}

.text-shadow-dark {
  text-shadow: 0 0 10px $psf-green;
}

.text-background-yellow-light {
  background-color: rgba($psf-text-yellow-light, 0.5);
}