::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba($psf-green-light, 0.95) 0%, rgba($psf-green, 0.95) 99%);
  border: 0px none #ffffff;
  border-radius: 50px;
}
// ::-webkit-scrollbar-thumb:hover {
//   background: #ffffff;
// }
// ::-webkit-scrollbar-thumb:active {
//   background: #000000;
// }
::-webkit-scrollbar-track {
  background: $psf-text-yellow-light;
  border: 0px none #ffffff;
  border-radius: 50px;
}
// ::-webkit-scrollbar-track:hover {
//   background: #666666;
// }
// ::-webkit-scrollbar-track:active {
//   background: #333333;
// }
// ::-webkit-scrollbar-corner {
//   background: transparent;
// }