@import "../../../styles/colors";

#psf-draft-admin-container {

}

#psf-draft-admin-container .table-id {
  width: 50px;
}

#psf-draft-admin-container .table-lastLogin {
  width: 175px;
}

#psf-draft-admin-container th {
  color: $psf-text-gray;
  font-weight: 700;
}

#psf-draft-admin-container td {
  color: $psf-text-yellow-light;
}
