@import '../../styles/colors';

#psf-footer-container {
  height: 25px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  background-color: rgba($psf-green, .85);
  border-top-left-radius: 80px 80px;
  border-top-right-radius: 80px 80px;
  padding: 5px 30px;
  color: $psf-text-gray;
  font-size: .85rem;
  z-index: 1000;
}

