@import "../../styles/_colors.scss";

#psf-ffl-legends-club {
  width: 100%;
}

#psf-ffl-carousel-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#psf-ffl-carousel {
  max-width: 900px;
  max-height: 450px;
}

#psf-ffl-carousel .carousel-caption {
  background-color: rgba($psf-green-light, 0.7);
}

#psf-ffl-carousel .carousel-caption h3 {
  color: $psf-yellow !important;
  font-weight: bold;
}

#psf-ffl-carousel img {
  border: 4px solid $psf-legends-silver;
  border-radius: 5px;
}
