@import "../../styles/colors";

#psf-draft-dashboard-container a {
  color: $psf-yellow;
}

#psf-draft-dashboard-container small {
  font-size: 50%;
}

#psf-draft-dashboard-container .number-text > h1 {
  font-size: 500%;
  color: $psf-green;
  font-weight: 900;
}

#psf-draft-dashboard-container .box-label {
  letter-spacing: 0.1em;
  font-weight: 200;
  text-transform: uppercase;
}
