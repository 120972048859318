@import '../../styles/colors';

#psf-ffl-home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#psf-ffl-home-container .logo-main-1 {
  border: 5px solid $psf-yellow;
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 3px;
}

#psf-ffl-home-container .logo-main-2 {
  border: 3px solid #1C2331;
  border-radius: 50%;
  background-color: $psf-green-alt;
  padding: 15px;
}

#psf-ffl-home-container .pulse {
  margin: 10px;
  max-width: 300px;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}