@import './_colors.scss';

.btn-psf-green {
    color: #fff;
    background-color: $psf_green;
    border-color: $psf_green_light;
}

.btn-psf-green:hover {
  background-color: $psf_green_light;
}