@import '../../styles/_colors.scss';

.countdown {
  text-shadow: 2px 2px 2px $psf_yellow;
  color: $psf_green;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

.countdown-col {
  display: inline-block;
  width: 75px;
}

.countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countdown-col-element h1 {
  font-weight: 900;
  margin: 0;
}