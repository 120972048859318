$psf-yellow: rgb(255,194,15); // #FFC20F;
$psf-green: rgb(0,50,1); // #003201;
$psf-green-alt: #06342D;
$psf-green-light: rgb(102,132,102); // #668466;
$psf-text-green-light: #ccd6cc;
$psf-text-yellow-light: rgb(247, 239, 153); // #F7EF99;
$psf-text-error: #cc0000;
$psf-text-warn: #ea9999;

$psf-white: rgb(248,248,255);
$psf-gray: rgb(128,128,128); // #808080
$psf-gray-dark: rgb(64,64,64); // #404040
$psf-text-gray: #E8F0FF;
$psf-text-header: #F7EF99;

$psf-legends-silver: #868686;