$enable-responsive-font-sizes: true;

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/react-toastify/scss/main.scss";
// @import url('https://fonts.googleapis.com/css?family=Work+Sans:300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;1,100&family=Open+Sans:wght@300&display=swap');
@import "./colors.scss";
@import "./text.scss";
@import "./scrollbar.scss";
@import "./buttons.scss";
@import "./input.scss";


* {
  margin: 0;
}

body {
  color: $psf-white;
  background: url('../images/background/psf-ffl-heartfailure-1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  // border-left: 5px solid $psf-yellow;
  // border-right: 5px solid $psf-yellow;
  font-family: 'Prompt', sans-serif;
  overflow: hidden;
}

nav {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2 {
  font-family: 'Merriweather', sans-serif;
}

#psf-heartfailure-app {
  background-color: rgba($psf-gray, 0.95);
  background: linear-gradient(0deg, rgba($psf-yellow, 0.75) 0%, rgba($psf-green-light, 0.95) 50%, rgba($psf-text-yellow-light, 0.95) 100%);
  // background: linear-gradient(0deg, rgba($psf-green, 0.95) 0%, rgba($psf-green-light, 0.95) 35%, rgba($psf-gray, 0.95) 100%);
  height: 100vh;

  // ### MOBILE SAFARI & CHROME VIEWPORT ISSUE FIX ###
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

#main-container {
  width: 100vw;
  height: calc(100% - 91px);
  /* height - header - bottom border/footer */
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

.psf-container {
  width: 100%;
  padding: 20px;
}

.overflow-scroll {
  overflow: auto !important;
}

.row-background {
  background-color: rgba($psf-gray-dark, 0.65);
}

.row-background-yellow {
  background-color: rgba($psf-yellow, 0.2);
}

.row-background-green {
  background-color: rgba($psf-green, 0.2);
}

.full {
  height: 100%;
  width: 100%;
}