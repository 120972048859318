@import "../../styles/colors";

#psf-draft-container,
#psf-draft-admin-container {
  width: 100%;
}

#psf-draft-container,
#psf-draft-admin-container a {
  color: $psf-yellow;
}

// #psf-draft-container .row-background {
//   background-color: rgba($psf-white, 0.2);
// }

#psf-draft-container .label {
  font-size: 1.25rem;
  font-weight: 700;
}

#psf-draft-container .sub-head-text {
  font-size: 0.9rem;
  font-weight: 700;
}

#psf-draft-container .list-group-item {
  background-color: $psf-green-light;
}

#psf-draft-container .list-group-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#psf-draft-container .list-group-item a {
  color: $psf-text-yellow-light !important;
}

#psf-draft-container .list-group-item:hover {
  background-color: $psf-text-yellow-light !important;
  color: $psf-green-alt;
}

#psf-draft-container .list-group-item:hover a {
  color: $psf-green-light !important;
}

#psf-draft-container .draft-closed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(114, 38, 26, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}